import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CloseIcon from '../assets/close-icon.svg';
import { InputField, SelectField } from './styledComponents';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 1rem;
  padding: 1.25rem;
  width: 53.875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  gap: 1.5625rem;
  display: grid;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

const FormGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
  gap: 0.625rem;
`;

const Button = styled.button`
  background: #00A667;
  border: none;
  border-radius: 0.75rem;
  color: white;
  padding: 1rem;
  cursor: pointer;
`;

const Card = styled.div`
  border-radius: 1rem;
  border: 0.0625rem solid #E9E6EC;
  opacity: 1;
  display: flex;
  align-items: center;
  display: grid;
  padding: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.0625rem solid #EDF1F2;
  padding: 1rem;
`;

const Title = styled.div`
  font-weight: 800;
  font-size: 1.25rem;
  line-height: 1.625rem;
`;

const PersonalDetails = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const GeneratePasswordButton = styled(Button)`
  background: #fff;
  color: #00A667;
  border: 0.0625rem solid #DBD9DE;
  padding: 0.75rem 1rem;
  width: 11rem;
  font-size: 0.875rem;
  height: 2.875rem;
`;

const CancelButton = styled(Button)`
  background: #fff;
  color: #00A667;
  border: 0.0625rem solid #DBD9DE;
`;

const DeleteButton = styled(Button)`
  background: #ff0000;
  color: #fff;
`;

const UserModal = ({ isVisible, onClose, userData = {}, mode = 'add' }) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    role: 'user',
    country: 'Nigeria',
    password: '',
    password_confirmation: '',
  });

  const [originalData, setOriginalData] = useState({});

  useEffect(() => {
    if (isVisible) {
      if (userData && (mode === 'view' || mode === 'edit')) {
        setOriginalData(userData);
        setFormData({
          first_name: userData.first_name || '',
          last_name: userData.last_name || '',
          email: userData.email || '',
          role: userData.role || 'user',
          country: userData.country || 'Nigeria',
          password: '',
          password_confirmation: '',
        });
      }
    } else {
      setFormData({
        first_name: '',
        last_name: '',
        email: '',
        role: 'user',
        country: 'Nigeria',
        password: '',
        password_confirmation: '',
      });
      setOriginalData({});
    }
  }, [isVisible, userData, mode]);

  const generatePassword = () => {
    const length = 12;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let newPassword = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      newPassword += charset.charAt(Math.floor(Math.random() * n));
    }
    setFormData(prevState => ({ ...prevState, password: newPassword, password_confirmation: newPassword }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('bearerToken');

    const updatedData = {
      ...(formData.first_name !== originalData.first_name && { first_name: formData.first_name }),
      ...(formData.last_name !== originalData.last_name && { last_name: formData.last_name }),
      ...(formData.country !== originalData.country && { country: formData.country }),
      ...(formData.role !== originalData.role && { role: formData.role }),
      ...(formData.password && { password: formData.password }),
      ...(formData.password_confirmation && { password_confirmation: formData.password_confirmation }),
    };

    try {
      if (mode === 'add') {
        // Submit POST request for adding a new user
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/users`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        toast.success('User added successfully');
        navigate(0)
      } else if (mode === 'edit') {
        // Submit PATCH request for updating user data
        if (Object.keys(updatedData).length > 0) {
          const response = await axios.patch(`${process.env.REACT_APP_BASE_URL}/users/${userData.id}`, updatedData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          toast.success('User updated successfully');
          navigate(0)
        } else {
          toast.info('No changes detected');
        }
      }
      onClose();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleDelete = async () => {
    const token = localStorage.getItem('bearerToken');
    try {
      // Submit DELETE request for deleting user
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/users/${userData.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      toast.success('User deleted successfully');
      onClose();
      navigate(0)
    } catch (err) {
      toast.error(err.message);
    }
  };

  if (!isVisible) return null;

  return (
    <ModalBackground>
      <ModalContainer>
        <Header>
          <Title>
            {mode === 'view' ? 'View User' : mode === 'edit' ? 'Edit User' : 'Add User'}
          </Title>
          <CloseButton onClick={onClose}><img src={CloseIcon} alt=' ' /></CloseButton>
        </Header>
        <Form onSubmit={handleSubmit}>
          <Card>
            <PersonalDetails>Personal details</PersonalDetails>
            <FormGroup>
              <InputField
                style={{ background: '#F9F5FF', flex: '1 1 0%' }}
                label='First Name'
                name="first_name"
                value={formData.first_name}
                placeholder="Enter first name"
                onChange={handleInputChange}
                readOnly={mode === 'view'}
              />
              <InputField
                style={{ background: '#F9F5FF', flex: '1 1 0%' }}
                label='Last Name'
                name="last_name"
                value={formData.last_name}
                placeholder="Enter last name"
                onChange={handleInputChange}
                readOnly={mode === 'view'}
              />
            </FormGroup>
            <FormGroup>
              <InputField
                style={{ background: '#F9F5FF', flex: '1 1 0%' }}
                type="email"
                label='Email Address'
                name="email"
                value={formData.email}
                placeholder="Enter email address"
                onChange={handleInputChange}
                readOnly={true} // Email should always be read-only
              />
              <SelectField
                style={{ background: '#F9F5FF', flex: '1 1 0%' }}
                label="Role"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
                disabled={mode === 'view'}>
                <option value='user'> User </option>
                <option value='admin'> Admin </option>
              </SelectField>
            </FormGroup>
            <FormGroup>
              <InputField
                style={{ background: '#F9F5FF', flex: '1 1 0%' }}
                label='Country'
                name="country"
                value={formData.country}
                placeholder="Enter country"
                onChange={handleInputChange}
                readOnly={mode === 'view'}
              />
            </FormGroup>
            {mode === 'add' && (
              <>
                <FormGroup>
                  <InputField
                    style={{ background: '#F9F5FF', flex: '1 1 0%' }}
                    label='Password'
                    type="password"
                    name="password"
                    value={formData.password}
                    placeholder="[Generated password]"
                    onChange={handleInputChange}
                  />
                  <InputField
                    style={{ background: '#F9F5FF', flex: '1 1 0%' }}
                    label='Confirm Password'
                    type="password"
                    name="password_confirmation"
                    value={formData.password_confirmation}
                    placeholder="Confirm password"
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <div style={{ padding: '0.5rem', display: 'grid', alignSelf: 'self-end' }}>
                  <GeneratePasswordButton
                    type="button"
                    onClick={generatePassword}
                  >
                    Generate Password
                  </GeneratePasswordButton>
                </div>
              </>
            )}
          </Card>
          <ModalFooter>
            <CancelButton
              type="button"
              onClick={onClose}
            >
              Cancel
            </CancelButton>
            {mode === 'edit' && (
              <DeleteButton type="button" onClick={handleDelete}>
                Delete
              </DeleteButton>
            )}
            {(mode === 'add' || mode === 'edit') && (
              <Button type="submit">{mode === 'add' ? 'Add User' : 'Edit User'}</Button>
            )}
          </ModalFooter>
        </Form>
      </ModalContainer>
    </ModalBackground>
  );
};

export default UserModal;
