import React, { useState } from 'react';
import styled from 'styled-components';
import { FaBell, FaChevronDown } from 'react-icons/fa';
import Logo from '../assets/logo.svg';
import LogoutImage from '../assets/sidebar/logout.svg';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import axios from 'axios';

const NavbarContainer = styled.div`
  width: calc(94% - ${({ isOpen }) => (isOpen ? '13.5rem' : '4rem')}); /* Adjust width based on sidebar state */
  height: 3.5rem;
  background-color: ${({ theme }) => theme.colors.brand.background16}; /* Example background color */
  color: ${({ theme }) => theme.colors.textDark}; /* Example text color */
  border-bottom: 0.065rem solid #D3FAE0;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
  position: fixed;
  top: 0;
  transition: left 0.3s ease, width 0.3s ease;
  z-index: 1000;
  padding-top: 0.3rem;
  
  @media (max-width: 769px) {
    width: calc(94% - ${({ isOpen }) => (isOpen ? '13.5rem' : '5rem')});
    padding: 0;
  }
`;

const LogoDiv = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  flex: 1;
  @media (max-width: 769px) {
    font-size: 1rem;
  }
`;

const NavbarItems = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const NotificationIcon = styled(FaBell)`
  font-size: 1.25rem;
  cursor: pointer;
`;

const UserProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative; /* To position the dropdown menu */
`;

const InitialsContainer = styled.div`
  border: 0.065rem solid #00A6674D;
  padding: 0.065rem;
  border-radius: 0.625rem;
`;

const Initials = styled.div`
  background-color: ${({ theme }) => theme.colors.brand.background11}; /* Example background color */
  color: ${({ theme }) => theme.colors.brand.background16}; /* Example text color */
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 0.75rem;
`;

const DropdownIcon = styled(FaChevronDown)`
  font-size: 1rem;
  cursor: pointer;
`;

const Popup = styled.div`
  position: absolute;
  background: white;
  border: 0.065rem solid #ddd;
  z-index: 1000;
  box-shadow: 0 1.25rem 1.25rem -0.625rem #26358033; /* 0px 20px 20px -10px */
  width: 14.4375rem; /* 231px */
  max-height: 12.5rem; /* 200px */
  padding: 0.625rem 1.25rem; /* 10px 20px */
  border-radius: 0.75rem; /* 12px */
  overflow-y: auto;
  top: ${({ position }) => position.top};
  bottom: ${({ position }) => position.bottom};
  left: ${({ position }) => position.left};
  right: ${({ position }) => position.right};
  transform: ${({ position }) => position.transform};

  div {
    padding: 0.5rem 1rem; /* 8px 16px */
    cursor: pointer;
    border-bottom: 0.065rem solid #EFEEF0; /* 1px */
    color: #514D57;

    &:hover {
      color: red;
      background: #f0f0f0;
    }

    &:last-child {
      border-bottom: none;
    }
  }
`;

const Fullname = styled.span`
  display: none;

  @media (min-width: 769px) {
    display: inline;
  }
`;

// Function to get initials from fullname
const getInitials = (fullname) => {
  const names = fullname.split(' ');
  const initials = names.map(name => name.charAt(0)).join('');
  return initials.toUpperCase();
};

const Navbar = ({ isOpen }) => {
  // State to manage dropdown visibility
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  
  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Retrieve the user object from localStorage
  const userObject = JSON.parse(localStorage.getItem('user'));
  const fullname = userObject ? userObject.full_name : 'John Doe';
  const initials = userObject ? getInitials(fullname) : 'JD';

  // Handle logout
  const handleLogout = async () => {
    const token = localStorage.getItem('bearerToken');
  
    try {
      // Make the POST request to /logout
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      // Log error if needed, but we proceed regardless of success or failure
      console.error('Logout failed', error);
    } finally {
      // This block will always run regardless of success or failure
      toast.success('Logged out successfully');
      localStorage.removeItem('bearerToken');
      localStorage.removeItem('user');
      localStorage.removeItem('auth');
      navigate('/');
    }
  };

  return (
    <NavbarContainer isOpen={isOpen}>
      <LogoDiv>
        <img src={Logo} alt='ProHealth' />
      </LogoDiv>
      <NavbarItems>
        <NotificationIcon />
        <UserProfile>
          <Fullname>{fullname}</Fullname>
          <InitialsContainer>
            <Initials>{initials}</Initials>
          </InitialsContainer>
          <DropdownIcon onClick={toggleDropdown} />
          {dropdownOpen && (
            <Popup
              position={{
                top: '2.5rem',
                bottom: 'auto',
                left: 'auto',
                right: '0',
                transform: 'none'
              }}
            >
              <div style={{ display: 'flex', gap: '0.3125rem' }} onClick={handleLogout}>
                <img src={LogoutImage} alt='Logout' /> Logout
              </div>
            </Popup>
          )}
        </UserProfile>
      </NavbarItems>
    </NavbarContainer>
  );
};

export default Navbar;
