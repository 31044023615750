// src/components/Sidebar.js
import React from 'react';
import styled from 'styled-components';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Open from '../assets/sidebar/sidebar-left.svg';
import Close from '../assets/sidebar/sidebar-right.svg';
import UsersIcon from '../assets/sidebar/user.svg';
import DashIcon from '../assets/sidebar/element-equal.svg';
import DonationsIcon from '../assets/sidebar/clipboard-text.svg';
import LogoutImage from '../assets/sidebar/logout.svg';
import { toast } from 'react-toastify';
import axios from 'axios';

const SidebarContainer = styled.div`
  width: ${({ isOpen }) => (isOpen ? '13.5rem' : '4rem')}; /* 216px -> 13.5rem and 64px -> 4rem */
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.brand.background11}; // Using background11 from theme
  color: ${({ theme }) => theme.colors.textLight};
  transition: width 0.3s ease;
`;

const SidebarToggle = styled.li`
  background: none;
  color: ${({ theme }) => theme.colors.textLight};
  font-size: 1.5rem;
  border-bottom: 1px solid #34CC3E;
  margin: 1rem 1.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 1rem;
`;

const SidebarList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const SidebarItem = styled.li`
  padding: 1rem 1.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0.75rem; /* 12px -> 0.75rem */
  background-color: ${({ isActive }) => (isActive ? '#00091A8C' : 'transparent')};
  transition: background-color 0.3s ease;
`;

const SidebarLink = styled(NavLink)`
  color: ${({ theme }) => theme.colors.brand.background36};
  text-decoration: none;
  margin-left: ${({ isOpen }) => (isOpen ? '1rem' : '0')}; /* Margin to space out icon and text */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  transition: width 0.3s ease;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.275rem;

  &.active {
    color: #16d056;
  }
`;
const SidebarButton = styled.div`
  color: ${({ theme }) => theme.colors.brand.background36};
  text-decoration: none;
  margin-left: ${({ isOpen }) => (isOpen ? '1rem' : '0')}; /* Margin to space out icon and text */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  transition: width 0.3s ease;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.275rem;
`;

const SidebarIcon = styled.div`
  width: 1.25rem;
  background: transparent;
  display: flex;
  align-items: center;

  img {
    filter: ${({ isActive }) => (isActive ? 'invert(52%) sepia(73%) saturate(748%) hue-rotate(119deg) brightness(94%) contrast(88%)' : 'none')};
  }
`;

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = (path) => location.pathname === path;

  const handleLogout = async () => {
    const token = localStorage.getItem('bearerToken');
  
    try {
      // Make the POST request to /logout
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      // Log error if needed, but we proceed regardless of success or failure
      console.error('Logout failed', error);
    } finally {
      // This block will always run regardless of success or failure
      toast.success('Logged out successfully');
      localStorage.removeItem('bearerToken');
      localStorage.removeItem('user');
      localStorage.removeItem('auth');
      navigate('/');
    }
  };

  return (
    <SidebarContainer isOpen={isOpen}>
      <SidebarList>
        <SidebarToggle onClick={toggleSidebar}>
          {isOpen ? <img src={Open} alt='Open' /> : <img src={Close} alt='Close' />}
          {isOpen && <SidebarButton onClick={toggleSidebar} isOpen={isOpen}>Minimise</SidebarButton>}
        </SidebarToggle>
        <SidebarItem isActive={isActive('/dashboard')} onClick={() => navigate('/dashboard')}>
          <SidebarIcon isActive={isActive('/dashboard')}><img src={DashIcon} alt='Dashboard' /></SidebarIcon>
          <SidebarLink isOpen={isOpen} to="/dashboard">
            Dashboard
          </SidebarLink>
        </SidebarItem>
        <SidebarItem isActive={isActive('/users')} onClick={() => navigate('/users')}>
          <SidebarIcon isActive={isActive('/users')}><img src={UsersIcon} alt='Users' /></SidebarIcon>
          <SidebarLink isOpen={isOpen} to="/users">
            Users
          </SidebarLink>
        </SidebarItem>
        <SidebarItem isActive={isActive('/donations')} onClick={() => navigate('/donations')}>
          <SidebarIcon isActive={isActive('/donations')}><img src={DonationsIcon} alt='Donations' /></SidebarIcon>
          <SidebarLink isOpen={isOpen} to="/donations">
            Donations
          </SidebarLink>
        </SidebarItem>
        <SidebarItem style={{ marginTop: '60vh' }} onClick={handleLogout}>
          <SidebarIcon><img src={LogoutImage} alt='Logout' /></SidebarIcon>
          <SidebarButton isOpen={isOpen}> Logout </SidebarButton>
        </SidebarItem>
      </SidebarList>
    </SidebarContainer>
  );
};

export default Sidebar;
