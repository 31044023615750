// src/App.js
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardWrapper from './components/Wrapper';
import { ToastContainer } from 'react-toastify';
import Login from './pages/login';
import PrivateRoute from './components/PrivateRoutes';
import 'react-toastify/dist/ReactToastify.css';
import RequireAuth from './context/RequireAuth';
import Dashboard from './pages/overview';
import Users from './pages/users';
import Donations from './pages/donations';


function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <Router>
        <Routes>
          {/* Routes that do not use the DashboardWrapper */}
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route element={<RequireAuth />}>
          {/* Routes that use the DashboardWrapper */}
          <Route element={<DashboardWrapper />}>
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path='/donations'
              element={
                <PrivateRoute>
                  <Donations />
                </PrivateRoute>
              }
            />
          </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
