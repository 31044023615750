import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import ActionIcon from '../assets/dashboard/morecircle.svg';
import ActiveIcon from '../assets/active.svg';
import InactiveIcon from '../assets/inactive.svg';
import Eye from '../assets/eye.svg';
import Tick from '../assets/tick-circle.svg';
import Edit from '../assets/edit.svg';
import UserModal from './UserModal';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 1rem;
  overflow: hidden;
  border: 0.0625rem solid #DFFBE7;
`;

const TableHeader = styled.th`
  background-color: #fff;
  color: #514D57;
  padding: 0.5rem 1rem;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.275rem;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f8f8f8;
  }
`;

const TableCell = styled.td`
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4875rem;
  text-align: left;
  color: #514D57;
`;

const Status = styled.span`
  display: flex;
  padding: 0.5rem;
  border-radius: 1.25rem;
  color: ${props => (props.active ? '#257D3A' : '#DA2C2C')};
  background-color: ${props => (props.active ? '#257D3A1A' : '#DA2C2C1A')};
  border: ${props => (props.active ? '0.0625rem solid #257D3A52' : '0.0625rem solid #DA2C2C52')};
  gap: 0.3125rem;
  justify-content: center;
  width: 4.25rem;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #514D57;
  font-size: 1.125rem;
  position: relative;
`;

const Popup = styled.div`
  position: absolute;
  background: white;
  border: 0.0625rem solid #ddd;
  z-index: 1000;
  box-shadow: 0rem 1.25rem 1.25rem -0.625rem #26358033;
  width: 14.4375rem;
  max-height: 12.5rem;
  padding: 0.625rem 1.25rem;
  border-radius: 0.75rem;
  overflow-y: auto;
  top: ${({ position }) => position.top};
  bottom: ${({ position }) => position.bottom};
  left: ${({ position }) => position.left};
  right: ${({ position }) => position.right};
  transform: ${({ position }) => position.transform};

  div {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-bottom: 0.0625rem solid #EFEEF0;
    color: #514D57;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background: #f0f0f0;
    }
  }
`;

const PopupItem = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    padding: 10px;
    max-width: 100vw;
  }
`;

const PaginationButton = styled.button`
  color: #00A667;
  background-color: white;
  padding: 10px 20px;
  border: 1px #00A667 solid;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0em;
  border-radius: 32px;
  margin: 0 5px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const NoDataMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  color: #666;
`;

const StyledTable = ({ data, onViewClick, onEditClick , currentPage, totalPages, onPageChange}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [popupPosition, setPopupPosition] = useState({});
  const popupRef = useRef(null);
  const tableRef = useRef(null);
  const actionButtonRefs = useRef([]);
  const itemsPerPage = 12;

  const currentPageData = data.slice(
    // (currentPage - 1) * itemsPerPage,
    // currentPage * itemsPerPage
  );

  const togglePopup = (index) => {
    if (showPopup && activeRow === index) {
      setShowPopup(false);
      return;
    }
    setActiveRow(index);
    setShowPopup(true);
    setPopupPosition(calculatePopupPosition(index));
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup]);

  const calculatePopupPosition = (index) => {
    const actionButtonRect = actionButtonRefs.current[index].getBoundingClientRect();
    const tableRect = tableRef.current.getBoundingClientRect();
    const popupHeight = 200;
    const spaceBelow = tableRect.bottom - actionButtonRect.bottom;
    const spaceAbove = actionButtonRect.top - tableRect.top;

    if (spaceBelow >= popupHeight) {
      return {
        top: `${actionButtonRect.height}px`,
        bottom: 'auto',
        left: 'auto',
        right: '0',
        transform: 'none'
      };
    } else if (spaceAbove >= popupHeight) {
      return {
        top: 'auto',
        bottom: `${actionButtonRect.height}px`,
        left: 'auto',
        right: '0',
        transform: 'none'
      };
    } else {
      return {
        top: '50%',
        bottom: 'auto',
        left: '-15.625rem',
        right: 'auto',
        transform: 'translateY(-50%)'
      };
    }
  };

  return (
    <>
    {currentPageData.length > 0 ? (
      <>
      <Table ref={tableRef}>
        <thead style={{ borderBottom: '0.0625rem solid #ddd' }}>
          <tr>
            <TableHeader>Avatar</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>Email</TableHeader>
            <TableHeader>Role</TableHeader>
            <TableHeader>Country</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader>Joined at</TableHeader>
            <TableHeader>Action</TableHeader>
          </tr>
        </thead>
        <tbody>
          {currentPageData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell><img src={row.avatar} alt={row.first_name} /></TableCell>
              <TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.role}</TableCell>
              <TableCell>{row.country}</TableCell>
              <TableCell>
                <Status active={!!row.email_verified_at}>
                  {row.email_verified_at ? <img src={ActiveIcon} alt='active' /> : <img src={InactiveIcon} alt='inactive' />} {row.email_verified_at ? 'Active' : 'Inactive'}
                </Status>
              </TableCell>
              <TableCell>{format(new Date(row.created_at), 'MMM dd, yyyy hh:mm:ss')}</TableCell>
              <TableCell>
                <ActionButton ref={el => (actionButtonRefs.current[index] = el)} onClick={() => togglePopup(index)}>
                  <img src={ActionIcon} alt='action' />
                  {showPopup && activeRow === index && (
                    <Popup ref={popupRef} position={popupPosition}>
                      <PopupItem onClick={() => onViewClick(row)}><img src={Eye} alt='' /> View</PopupItem>
                      <PopupItem onClick={() => onEditClick(row)}><img src={Edit} alt='' /> Update</PopupItem>
                      {/* <PopupItem><img src={Tick} alt='' /> Activate</PopupItem> */}
                    </Popup>
                  )}
                </ActionButton>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
      <PaginationContainer>
            <PaginationButton
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </PaginationButton>
            {[...Array(totalPages).keys()].map((page) => (
              <PaginationButton
                key={page + 1}
                onClick={() => onPageChange(page + 1)}
                disabled={currentPage === page + 1}
              >
                {page + 1}
              </PaginationButton>
            ))}
            <PaginationButton
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </PaginationButton>
          </PaginationContainer>
          </>
        ) : (
        <NoDataMessage>No ongoing project</NoDataMessage>
      )}
    </>
  );
};

export default StyledTable;
