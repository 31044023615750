// theme.js
export const theme = {
    colors: {
      primary: '#00A667',
      lightPurple: '#F6F0FC',
      brand: {
        background1: '#F1E9FE',
        background2: '#E5D7FD',
        background3: '#D1B7FB',
        background4: '#B489F7',
        background5: '#F9F5FF',
        background6: '#985BF1',
        background7: '#813AE3',
        background8: '#6D29C7',
        background9: '#5D26A3',
        background10: '#4D2083',
        background11: '#00A667',
        background12: '#EFEFEF',
        background13: '#DCDCDC',
        background14: '#BDBDBD',
        background15: '#989898',
        background16: '#FFFFFF',
        background17: '#7C7C7C',
        background18: '#656565',
        background19: '#525252',
        background20: '#464646',
        background21: '#3D3D3D',
        background22: '#292929',
        background23: '#CDFAF2',
        background24: '#9BF4E5',
        background25: '#60E8D5',
        background26: '#30D1C1',
        background27: '#F0FDFA',
        background28: '#19C6B7',
        background29: '#0F9289',
        background30: '#11746E',
        background31: '#125D5A',
        background32: '#144D4A',
        background33: '#052E2E',
        background34: '#EFEEF0',
        background35: '#DBD9DE',
        background36: '#FFF',
        background37: '#96929E',
        background38: '#F7F7F8',
        background39: '#797483',
        background40: '#625E6B',
        background41: '#514D57',
        background42: '#45424A',
        background43: '#3C3A40',
        background44: '#302E33',
        background45: '#333333',
        background46: '#757575',
        background47: '#000000',
        background48: '#FFFFFF',
        background49: '#E5E5E5',
      },
      semantics: {
        background1: '#FCF0C5',
        background2: '#F9DF8F',
        background3: '#F6C54E',
        background4: '#F2B12E',
        background5: '#FDF8E9',
        background6: '#E19311',
        background7: '#C26F0C',
        background8: '#9B4E0D',
        background9: '#803F13',
        background10: '#6D3416',
        background11: '#401908',
        background12: '#DEE8FB',
        background13: '#C5D7F8',
        background14: '#9EBBF2',
        background15: '#6F9AEB',
        background16: '#F1F5FD',
        background17: '#4E82E3',
        background18: '#4176D9',
        background19: '#3064C5',
        background20: '#2C55A1',
        background21: '#29477F',
        background22: '#1D2E4E',
        background23: '#FDE5E3',
        background24: '#FDD0CB',
        background25: '#FAAFA7',
        background26: '#F67F73',
        background27: '#FEF3F2',
        background28: '#EC5647',
        background29: '#D93B2B',
        background30: '#B62D1F',
        background31: '#97281D',
        background32: '#7D281F',
        background33: '#44100B',
        background34: '#D6F1DC',
        background35: '#B2E4BE',
        background36: '#85D197',
        background37: '#5EBD74',
        background38: '#ECF9EF',
        background39: '#3D9952',
        background40: '#308043',
        background41: '#286336',
        background42: '#254F2F',
        background43: '#23442B',
        background44: '#0F2414',
      },
      typography: {
        poppins800: 'Poppins, 800, 155.69px, 155.69px, -0.01em, left',
        poppins700: 'Poppins, 700, 18px, 27px, 0em, left',
        openSans400: 'Open Sans, 400, 155.69px, 155.69px, -0.01em, left',
      }
    }
  };
  