// DashboardWrapper.js
import React, { useState } from 'react';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  margin-top: 4rem; /* Adjust based on navbar height */
  overflow: hidden;
`;

const MainContent = styled.div`
  flex-grow: 1;
  margin-left: ${({ isOpen }) => (isOpen ? '13.5rem' : '4rem')}; /* 216px -> 13.5rem and 64px -> 4rem */
  padding-top: 1rem; /* Adjust padding to avoid overlap with the navbar */
  padding-left: 1rem; /* Optional padding for better spacing */
  padding-right: 1rem; /* Optional padding for better spacing */
  transition: margin-left 0.3s ease;
  position: relative;
  overflow: hidden; /* Prevent horizontal overflow */
  overflow-y: auto;
`;

const Content = styled.div`
  padding: 0 1.25rem;
  /* Adjust width to account for padding (1.25rem on each side) */
  overflow: hidden; /* Prevent horizontal overflow */
  max-width: 100%;
`;

const DashboardWrapper = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Wrapper>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <MainContent isOpen={isOpen}>
        <Navbar isOpen={isOpen} />
        <Content>
          <Outlet />
        </Content>
      </MainContent>
    </Wrapper>
  );
};

export default DashboardWrapper;
