import styled from "styled-components";
import backgroundImage from '../assets/background.png';
import Eye from '../assets/login/frame.svg';
import React, { useState } from 'react';

export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  ${'' /* background: url(${backgroundImage}) no-repeat center center/cover; */}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #D8FFF0;
    opacity: 0.9;
  }
`;

export const FormContainer = styled.div`
  padding: 2rem 2.5rem 2.5rem 2.5rem; /* 32px 40px 40px 40px to rem */
  gap: 1rem; /* 40px to rem */
  border-radius: 1.5rem; /* 24px 0px 0px 0px to rem */
  box-shadow: 0 0.25rem 1.25rem 0 #00000014; /* 0px 4px 20px to rem */
  background: #fff;
  display: flex;
  flex-direction: column;
  z-index: 2; /* Ensure form is above the overlay */
  width: 28.625rem; /* 458px to rem */
`;

export const H1 = styled.p`
  margin: 0 !important;
  padding: 0 !important;
  font-size: 1.5rem; /* 24px to rem */
  font-weight: 700;
  line-height: 1.95rem; /* 31.2px to rem */
  text-align: center;
  color: ${({ theme }) => theme.colors.brand.background11};
`;

export const RegularBodyText = styled.p`
  font-family: Open Sans;
  font-size: 0.875rem; /* 14px to rem */
  font-weight: 400;
  line-height: 1.4875rem; /* 23.8px to rem */
  text-align: center;
  color: ${({ theme }) => theme.colors.brand.background41};
`;

export const PrimaryButton = styled.button`
  padding: 1rem 2rem; /* 16px 32px to rem */
  gap: 0.75rem; /* 12px to rem */
  border-radius: 0.625rem; /* 10px to rem */
  background-color: ${({ theme }) => theme.colors.primary}; /* Use your theme color for primary */
  color: ${({ theme }) => theme.colors.brand.background16};
  font-size: 0.875rem; /* 14px to rem */
  line-height: 0.875rem; /* 14px to rem */
  text-align: center;
  font-weight: 600;
  border: none;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease-out, transform 0.3s ease-out; /* Hover effect and click animation */

  &:hover {
    background-color: ${({ theme }) => theme.colors.brand.background8}; /* Lighter primary color on hover */
  }

  &:active {
    transform: scale(0.98); /* Slightly reduce size on click */
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.125rem; /* 2px to rem */
  opacity: 1;
  width: 100%;
`;

const Label = styled.label`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem; /* 12px to rem */
  font-weight: 600;
  line-height: 1.125rem; /* 18px to rem */
  text-align: left;
  color: #514D57;
  padding: 0 0.125rem; /* 2px to rem */
`;

const Input = styled.input`
  padding: 0.75rem 1rem; /* 12px 16px to rem */
  border: 0.0625rem solid #EFEEF0; /* 1px to rem */
  border-radius: 0.625rem; /* 10px to rem */
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem; /* 14px to rem */
  color: #514D57;
`;

const InputSelect = styled.select`
  padding: 0.75rem 1rem; /* 12px 16px to rem */
  border: 0.0625rem solid #EFEEF0; /* 1px to rem */
  border-radius: 0.625rem; /* 10px to rem */
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem; /* 14px to rem */
  color: #514D57;
`;

const PasswordFieldContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const TogglePasswordButton = styled.img`
  position: absolute;
  right: 1rem;
  cursor: pointer;
`;

const CounterCard = styled.div`
  width: 21.3125rem; /* 341px to rem */
  padding: 1.6875rem 1.4375rem; /* 27px 23px to rem */
  gap: 1.375rem; /* 22px to rem */
  border-radius: 1rem; /* 16px 0px 0px 0px to rem */
  border: 0.0625rem solid #E9E6EC;
  opacity: 1;
  display: flex;
  align-items: center;
`;

const SvgContainer = styled.div`
  width: 4.5rem; /* 72px to rem */
  height: 4.5rem; /* 72px to rem */
`;

const Number = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 2rem; /* 32px to rem */
  font-weight: 700;
  line-height: 2.6rem; /* 41.6px to rem */
  text-align: left;
  color: ${({ theme }) => theme.colors.text}; /* Adjust based on your theme */
`;

const Text = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem; /* 12px to rem */
  font-weight: 400;
  line-height: 1.275rem; /* 20.4px to rem */
  text-align: left;
  color: ${({ theme }) => theme.colors.textSecondary}; /* Adjust based on your theme */
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  gap: 0.3125rem; /* 5px to rem */
  padding: 0.3125rem; /* 5px to rem */
  border: 0.0625rem solid #EDF1F2; /* 1px to rem */
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 0.5rem; /* 8px to rem */
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 1.25rem; /* 20px to rem */
  height: 1.25rem; /* 20px to rem */
  background: ${props => (props.checked ? '#00A667' : 'white')};
  border-radius: 0.375rem; /* 6px to rem */
  transition: all 150ms;
  border: 0.125rem solid #E5D7FD; /* 2px to rem */

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 0.1875rem pink; /* 3px to rem */
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxText = styled.div`
  font-size: 0.75rem; /* 12px to rem */
  font-weight: 500;
  line-height: 1.125rem; /* 18px to rem */
  color: #5B6C71;
`;

export const Card = ({ svg: SvgComponent, number, text }) => {
  const formattedNumber = new Intl.NumberFormat().format(number);
  return (
    <CounterCard>
      <SvgContainer>
        <SvgComponent />
      </SvgContainer>
      <ContentContainer>
        <Number>{formattedNumber}</Number>
        <Text>{text}</Text>
      </ContentContainer>
    </CounterCard>
  );
};

const Checkbox = ({ checked, ...props }) => (
  <div>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </div>
);

export const CheckComponent = ({ checked, onChange }) => (
  <CheckBoxContainer onClick={onChange}>
    <Checkbox checked={checked} />
    <CheckboxText>Select</CheckboxText>
  </CheckBoxContainer>
);

export const InputField = ({ label, name, type = 'text', ...props }) => {
  const [inputType, setInputType] = useState(type);

  const togglePasswordVisibility = () => {
    setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      {type === 'password' ? (
        <PasswordFieldContainer>
          <Input id={name} name={name} style={{ width: '100%' }} type={inputType} {...props} />
          <TogglePasswordButton
            src={Eye}
            alt="Toggle Password Visibility"
            onClick={togglePasswordVisibility}
          />
        </PasswordFieldContainer>
      ) : (
        <Input id={name} name={name} type={type} {...props} />
      )}
    </Container>
  );
};

export const SelectField = ({ label, name, type = 'text', ...props }) => {
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <InputSelect id={name} name={name} style={{ width: '100%' }} {...props} />
    </Container>
  );
};
