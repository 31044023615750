import React from 'react';
import styled, { keyframes } from 'styled-components';
import logo from '../assets/logo.svg';
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const LoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1900;
`;

const Logo = styled.img`
  width: 300px; 
  height: 300px;
  animation: ${pulse} 1.5s infinite ease-in-out;
`;

const GlobalLoader = ({ loading }) => {
  if (!loading) return null;

  return (
    <LoadingScreen>
      <Logo src={logo} alt="Loading" />
    </LoadingScreen>
  );
};

export default GlobalLoader;
