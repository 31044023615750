import React from 'react';
import styled from 'styled-components';
import SearchIcon from '../assets/search.svg';
import FilterIcon from '../assets/filter.svg';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.25rem; /* 20px */
  background-color: #fff;
  border-bottom: 0.065rem solid #ddd; /* 1px */
`;

const Title = styled.h1`
  font-size: 1.5rem; /* 24px */
  font-weight: bold;
  color: #000;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem; /* 10px */
`;

const SearchInput = styled.input`
  border-radius: 0.75rem; /* 12px */
  background: #DFFBE7;
  outline: none;
  padding: 0.75rem 1rem; /* 12px 16px */
  font-size: 1rem; /* 16px */
  color: #514D57;
  border: none;
  &::placeholder {
    color: #666;
  }
`;

const SearchButton = styled.button`
  background: #00A667;
  border: none;
  border-radius: 0.75rem; /* 12px */
  padding: 1rem 0.75rem; /* 16px 12px */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem; /* 12px */
  width: 2.5rem; /* 40px */
  height: 2.5rem; /* 40px */
`;

const SearchIconImg = styled.img`
  width: 1rem; /* 16px */
  height: 1rem; /* 16px */
`;

const FilterButton = styled.button`
  background: #fff;
  border: 0.065rem solid #DBD9DE; /* 1px */
  border-radius: 0.5rem; /* 8px */
  color: #514D57;
  padding: 0.75rem; /* 12px */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.3125rem; /* 5px */
`;

const ActionButton = styled.button`
  background: #00A667;
  border: none;
  border-radius: 0.75rem; /* 12px */
  color: #fff;
  padding: 1rem; /* 16px */
  cursor: pointer;
  height: 2.5rem; /* 40px */
  align-items: center;
  justify-content: center;
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem; /* 10px */
`;

const PageHeader = ({ title, onSearch, actionButtonTitle, onActionButtonClick, showFilterButton, onFilterClick }) => {
  return (
    <HeaderContainer>
      <Title>{title}</Title>
      <ButtonContainer>
        {showFilterButton && (
          <FilterButton onClick={onFilterClick}>
            <img src={FilterIcon} alt="Filter" />
            Filter
          </FilterButton>
        )}
        <SearchContainer>
          <SearchInput placeholder="Search" />
          <SearchButton onClick={onSearch}>
            <SearchIconImg src={SearchIcon} alt="Search" />
          </SearchButton>
        </SearchContainer>
        <ActionButton onClick={onActionButtonClick}>
          {actionButtonTitle}
        </ActionButton>
      </ButtonContainer>
    </HeaderContainer>
  );
};

export default PageHeader;
