// src/components/Login.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Background,
  FormContainer,
  H1,
  InputField,
  PrimaryButton,
  RegularBodyText,
} from '../components/styledComponents';
import Logo from '../assets/logo.svg';
import styled from 'styled-components';
import GlobalLoader from '../components/GlobalLoader';
import { toast } from "react-toastify";
import axios from "axios";
import AuthContext from "../context/AuthProvider";

const CenteredContainer = styled.div`
  gap: 1rem;
  display: grid;
  justify-items: center;
`;

const LogoImage = styled.img`
  height: 3.5rem; /* 56px to rem */
`;

const TextContainer = styled.div`
  gap: 0.25rem;
  display: grid;
`;

const Form = styled.form`
  gap: 2rem;
  display: grid;
`;

const FormGroup = styled.div`
  gap: 0.5rem; /* 8px to rem */
  display: grid;
`;

const FormSubGroup = styled.div`
  gap: 1rem;
  display: grid;
`;

const ForgotPasswordLink = styled.div`
  text-align: left;
  display: flex;
  justify-content: flex-end;
`;

const ErrorMessage = styled.div`
  color: red;
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);
  

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/signin`,
        JSON.stringify({ email, password }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response?.status === 401) {
        toast.error("Invalid email or password");
        setLoading(false); // Stop loading
        return;
      }

      const token = response?.data?.data?.token;
      const role = response?.data?.data?.user?.role;

      if (token && (role === 'admin')) {
        // Set token in local storage
        localStorage.setItem("bearerToken", token);
        localStorage.setItem("email", email);

        // Fetch user data using the token
        const userResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/auth/user`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = userResponse.data.data;
        localStorage.setItem("user", JSON.stringify(responseData));

        // Update auth state in context
        setAuth({ token, email, ...responseData });

        // Clear email and password state
        setEmail("");
        setPassword("");

        // Display success message and navigate to dashboard
        toast.success("Login successful!");
        navigate("/dashboard");
      } else {
        toast.error("User data not found");
      }
    } catch (err) {
      if (!err?.response) {
        toast.error("No server response");
      } else if (err.response?.status === 400) {
        toast.error("Missing Email or Password");
      } else if (err.response?.status === 401) {
        toast.error(
          err.response?.data?.errors?.message || "Invalid email or password"
        );
      } else {
        toast.error("Login Failed");
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <Background>
      <FormContainer>
        <CenteredContainer>
          <LogoImage src={Logo} alt="Logo" />
          <TextContainer>
            <H1>Welcome Back, Admin</H1>
            <RegularBodyText>
              Enter your details below to sign in to your account
            </RegularBodyText>
          </TextContainer>
        </CenteredContainer>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <FormSubGroup>
              <InputField
                label="Email"
                name="email"
                placeholder="Enter email address"
                value={email}
                onChange={handleEmailChange}
              />
              <InputField
                label="Password"
                name="password"
                placeholder="Enter password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </FormSubGroup>
            <ForgotPasswordLink>
              <a style={{textDecoration:'none', color:'#000'}} href="/forgot-password">Forgot Password?</a>
            </ForgotPasswordLink>
          </FormGroup>
          <PrimaryButton type="submit" disabled={loading}>
            {loading ? 'Signing in...' : 'Sign in'}
          </PrimaryButton>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Form>
      </FormContainer>
      <GlobalLoader loading={loading} />
    </Background>
  );
};

export default Login;
