import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import StyledTable from '../components/TableComponent';
import PageHeader from '../components/pageheader';
import AddIcon from '../assets/add.svg';
import UserModal from '../components/UserModal';
import { toast } from 'react-toastify';
import AnalyticsCard from '../components/AnalyticsCard';
import ProjectModal from '../components/formModal';

const Container = styled.div`
  display: grid;
  gap: 2rem;
  padding: 1rem;
  padding-top: 0;
  box-sizing: border-box;
`;

const ActionButtonTitle = styled.span`
  display: flex;
  gap: 0.3125rem; /* 5px to rem */
`;

const AddImage = styled.img`
  alt: 'Add';
`;

const Dashboard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState('add');
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);

  const handleActionButtonClick = () => {
    setModalMode('add');
    setSelectedProject(null);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setSelectedProject(null);
  };

  return (
    <Container>
      <PageHeader
        title='Projects'
        showFilterButton={true}
        actionButtonTitle={
          <ActionButtonTitle>
            <AddImage src={AddIcon} />
            Add
          </ActionButtonTitle>
        }
        onActionButtonClick={handleActionButtonClick}
      />
      <AnalyticsCard />
      <ProjectModal isVisible={isModalVisible}
        onClose={handleClose}
        project={selectedProject}
        isEditMode={isEditMode}
      />
    </Container>
  );
};

export default Dashboard;
