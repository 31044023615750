import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import StyledTable from '../components/TableComponent';
import PageHeader from '../components/pageheader';
import AddIcon from '../assets/add.svg';
import UserModal from '../components/UserModal';
import { toast } from 'react-toastify';
import axios from 'axios';
import GlobalLoader from '../components/GlobalLoader';

const Container = styled.div`
  display: grid;
  gap: 2rem;
  padding: 1rem;
  padding-top: 0;
  box-sizing: border-box;
`;

const ActionButtonTitle = styled.span`
  display: flex;
  gap: 0.3125rem; /* 5px to rem */
`;

const AddImage = styled.img`
  alt: 'Add';
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
`;

const PaginationButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  &:disabled {
    cursor: not-allowed;
    background-color: #ccc;
  }
`;

const Users = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState('add');
  const [selectedUser, setSelectedUser] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('bearerToken');
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [totalPages, setTotalPages] = useState(1); // Total pages state
  const [totalUsers, setTotalUsers] = useState(); // Total pages state

  const fetchUsersData = async (page) => {
    setLoading(true);
    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users?page=${page}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          setUsersData(response.data.data.data); 
          setTotalPages(response.data.data.last_page)
          setTotalUsers(response.data.data.total)
          setLoading(false);
    } catch (error) {
      toast.error('Failed to fetch users data.');
    }
  };

  useEffect(() => {
    fetchUsersData(currentPage);
  }, [currentPage]);

  const handleActionButtonClick = () => {
    setModalMode('add');
    setSelectedUser(null);
    setIsModalVisible(true);
  };

  const handleViewClick = (userId) => {
    setModalMode('view');
    setSelectedUser(userId);
    setIsModalVisible(true);
  };

  const handleEditClick = (userId) => {
    setModalMode('edit');
    setSelectedUser(userId);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedUser(null);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Container>
      <PageHeader
        title={`Users(${totalUsers})`}
        showFilterButton={true}
        actionButtonTitle={
          <ActionButtonTitle>
            <AddImage src={AddIcon} />
            Add
          </ActionButtonTitle>
        }
        onActionButtonClick={handleActionButtonClick}
      />
      <StyledTable data={usersData} onViewClick={handleViewClick} onEditClick={handleEditClick} currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      <UserModal isVisible={isModalVisible} onClose={handleCloseModal} mode={modalMode} userData={selectedUser} />
      <GlobalLoader loading={loading} />
    </Container>
  );
};

export default Users;
