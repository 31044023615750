import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import RoleTable from '../components/RolesTable';
import PageHeader from '../components/pageheader';
import AddIcon from '../assets/add.svg';
import FormModal from '../components/formModal';
import { toast } from 'react-toastify';
import DonationsHistory from '../components/History';
import { SiTrueup } from 'react-icons/si';

const Container = styled.div`
  display: grid;
  gap: 2rem;
  padding: 1rem;
  box-sizing: border-box;
`;

const Card = styled.div`
  border-radius: 1rem;
  border: 0.0625rem solid #E9E6EC;
  opacity: 1;
  display: grid;
  padding: 1rem;
`;

const Title = styled.div`
  font-size: 1.25rem; /* 20px to rem */
  font-weight: 800;
  line-height: 1.625rem; /* 26px to rem */
  text-align: left;
  padding: 1rem 0;
`;

const ActionButtonTitle = styled.span`
  display: flex;
  gap: 0.3125rem; /* 5px to rem */
`;

const AddImage = styled.img`
  alt: 'Add';
`;

const Donations = () => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  const handleActionButtonClick = () => {
    setSelectedRole(null);
    setIsModalVisible(true);
    setIsEditMode(false);
  };

  const handleEdit = (role) => {
    setSelectedRole(role);
    setIsModalVisible(true);
    setIsEditMode(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Container>
        {/* <RoleTable data={null} onEdit={handleEdit} /> */}
      <FormModal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        role={selectedRole}
        isEditMode={isEditMode}
      />
      <DonationsHistory />
    </Container>
  );
};

export default Donations;
