import React from 'react';
import styled from 'styled-components';
import ActionIcon from '../assets/edit.svg';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 0.75rem; /* 12px */
  overflow: hidden;
  border: 0.065rem solid #EDF1F2; /* 1px */
`;

const TableHeader = styled.th`
  background-color: #fff;
  color: #000;
  padding: 0.5rem; /* 8px */
  text-align: left;
  font-size: 0.75rem; /* 12px */
  font-weight: 700;
  line-height: 1.275rem; /* 20.4px */
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f8f8f8;
  }
`;

const TableCell = styled.td`
  padding: 0.5rem; /* 8px */
  border-bottom: 0.065rem solid #ddd; /* 1px */
  color: #514D57;
  font-weight: 400;
  line-height: 1.4875rem; /* 23.8px */
  text-align: left;
  font-size: 0.875rem; /* 14px */
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #514D57;
  display: flex;
  align-items: center;
  gap: 0.5rem; /* 8px */
  font-size: 0.875rem; /* 14px */
  font-weight: 400;
  line-height: 1.4875rem; /* 23.8px */
  text-align: left;
`;

const ActionIconImg = styled.img`
  width: 1rem; /* 16px */
  height: 1rem; /* 16px */
`;

const RoleTable = ({ data, onEdit }) => {
  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>Roles</TableHeader>
          <TableHeader>Action</TableHeader>
        </tr>
      </thead>
      <tbody>
        {data?.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.name}</TableCell>
            <TableCell>
              <ActionButton onClick={() => onEdit(row)}>
                <ActionIconImg src={ActionIcon} alt='action' /> Update
              </ActionButton>
            </TableCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

export default RoleTable;
