import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import CloseIcon from '../assets/close-icon.svg';
import { InputField, SelectField } from './styledComponents'; // Import your styled components
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify styles
import GlobalLoader from './GlobalLoader';
import { useNavigate } from 'react-router-dom';


const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  border-radius: 1rem;
  padding: 1.25rem;
  width: 90vw;
  max-width: 53.875rem; /* 862px */
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  gap: 1.5625rem;
  display: grid;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1.25rem;
  gap: 0.625rem;
`;

const Button = styled.button`
  background: #00A667;
  border: none;
  border-radius: 0.75rem;
  color: white;
  padding: 1rem;
  cursor: pointer;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.0625rem solid #EDF1F2;
  padding: 1rem;
  font-weight: 800;
  font-size: 1.25rem;
  line-height: 1.625rem;
`;

const Select = styled.select`
  padding: 0.625rem;
  border: 0.0625rem solid #DBD9DE;
  border-radius: 0.375rem;
`;

const Loader = styled.div`
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ProjectModal = ({ isVisible, onClose, projectId, isEditMode }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [goal, setGoal] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('bearerToken');
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  // Fetch categories when the modal is opened
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/categories`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCategories(response.data.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    if (isVisible) {
      fetchCategories();
    }
  }, [isVisible]);

  // Fetch project details when the modal is opened in edit mode and projectId is provided
  useEffect(() => {
    const fetchProjectDetails = async () => {
      if (isEditMode && projectId) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const projectData = response.data.data;
          setName(projectData.name || '');
          setDescription(projectData.description || '');
          setGoal(projectData.goal || '');
          setCategoryId(projectData.category_id || '');
          setStartDate(projectData.start_date || '');
          setEndDate(projectData.end_date || '');
          setCoverImage(projectData.cover_image || null);
        } catch (error) {
          console.error('Error fetching project details:', error);
          toast.error('Failed to load project details. Please try again.');
        }
      }
    };

    if (isVisible) {
      fetchProjectDetails();
    }
  }, [isVisible, isEditMode, projectId]);

  if (!isVisible) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    // Prepare form data for submission
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('goal', goal);
    formData.append('category_id', categoryId);
    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    if (coverImage) formData.append('cover_image', coverImage);

    try {
      let response;
      if (isEditMode) {
        response = await axios.put(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}/update`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('Project updated successfully!');
        navigate(0);
      } else {
        response = await axios.post(`${process.env.REACT_APP_BASE_URL}/projects`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('Project added successfully!');
        navigate(0);
      }
      console.log('Response from backend:', response.data);
      onClose();
    } catch (error) {
      console.error('Error submitting project:', error);
      toast.error('Failed to submit project. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleDelete = async () => {
    if (isEditMode && projectId) {
      try {
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/projects/${projectId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success('Project deleted successfully!');
        navigate(0);
        onClose();
      } catch (error) {
        console.error('Error deleting project:', error);
        toast.error('Failed to delete project. Please try again.');
      }
    }
  };

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalHeader>
          <div>{isEditMode ? 'Edit Project' : 'Add Project'}</div>
          <CloseButton onClick={onClose}>
            <img src={CloseIcon} alt="Close" />
          </CloseButton>
        </ModalHeader>
        <Form onSubmit={handleSubmit}>
          <div style={{ display: 'flex' }}>
            <InputField
              label="Project Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter project name"
            />
            <InputField
              label="Cover Image"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <InputField
              label="Goal Amount"
              type="number"
              value={goal}
              onChange={(e) => setGoal(e.target.value)}
              placeholder="Enter project goal"
            />
            <SelectField
              label={'Category'}
              value={categoryId}
              name={'category'}
              onChange={(e) => setCategoryId(e.target.value)}
            >
              <option value="" disabled>Select a category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </SelectField>
          </div>
          <div style={{ display: 'flex' }}>
            <InputField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <InputField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <InputField
            label="Description"
            value={description}
            type="textarea"
            as="textarea"
            rows="4"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter project description"
          />
          <ModalFooter>
            <Button
              type="button"
              onClick={onClose}
              style={{
                background: '#fff',
                color: '#00A667',
                border: '0.0625rem solid #DBD9DE',
              }}
            >
              Cancel
            </Button>
            {isEditMode && (
              <Button
                type="button"
                onClick={handleDelete}
                style={{ background: '#ff0000', color: '#fff' }}
              >
                Delete
              </Button>
            )}
            <Button type="submit">
              {loading ? <Loader /> : (isEditMode ? 'Update Project' : 'Add Project')}
            </Button>
          </ModalFooter>
        </Form>
      </ModalContainer>
      <GlobalLoader loading={loading} />
    </ModalBackground>
  );
};

export default ProjectModal;
