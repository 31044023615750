import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem('bearerToken');
  const location = useLocation();

  if (!isAuthenticated) {
    toast.info('Please sign in');
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
