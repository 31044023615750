import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import SearchIcon from '../assets/search.svg';
import FilterIcon from '../assets/filter.svg';
import GlobalLoader from './GlobalLoader';
import { format } from 'date-fns';  // Make sure to install date-fns for date formatting

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.25rem; /* 20px */
  background-color: #fff;
  border-bottom: 0.065rem solid #ddd; /* 1px */
`;

const Title = styled.h1`
  font-size: 1.5rem; /* 24px */
  font-weight: bold;
  color: #000;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem; /* 10px */
`;

const SearchInput = styled.input`
  border-radius: 0.75rem; /* 12px */
  background: #DFFBE7;
  outline: none;
  padding: 0.75rem 1rem; /* 12px 16px */
  font-size: 1rem; /* 16px */
  color: #514D57;
  border: none;
  &::placeholder {
    color: #666;
  }
`;

const SearchButton = styled.button`
  background: #00A667;
  border: none;
  border-radius: 0.75rem; /* 12px */
  padding: 1rem 0.75rem; /* 16px 12px */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem; /* 12px */
  width: 2.5rem; /* 40px */
  height: 2.5rem; /* 40px */
`;

const SearchIconImg = styled.img`
  width: 1rem; /* 16px */
  height: 1rem; /* 16px */
`;

const FilterButton = styled.button`
  background: #fff;
  border: 0.065rem solid #DBD9DE; /* 1px */
  border-radius: 0.5rem; /* 8px */
  color: #514D57;
  padding: 0.75rem; /* 12px */
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.3125rem; /* 5px */
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
`;

const Thead = styled.thead`
  background-color: #f4f4f4;
`;

const Th = styled.th`
  padding: 1rem;
  text-align: left;
  font-weight: 700;
  border-bottom: 0.065rem solid #ddd;
  font-size: 0.875rem; /* 14px */
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const Td = styled.td`
  padding: 1rem;
  text-align: left;
  font-size: 0.875rem; /* 14px */
  border-bottom: 0.065rem solid #ddd;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  max-width: 100vw;
`;

const PaginationButton = styled.button`
  color: #00A667;
  background-color: white;
  padding: 10px 20px;
  border: 1px #00A667 solid;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  border-radius: 32px;
  margin: 0 5px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const NoDataMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  color: #666;
`;

const FilterSelect = styled.select`
  padding: 0.75rem;
  font-size: 1rem; /* 16px */
  border-radius: 0.75rem; /* 12px */
  border: 0.065rem solid #ddd; /* 1px */
  outline: none;
  max-width: 250px;
  background: #fff;
`;

const DonationsHistory = () => {
  const [donations, setDonations] = useState([]);
  const [filteredDonations, setFilteredDonations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [projectFilter, setProjectFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDonations(currentPage);
  }, [currentPage]);

  useEffect(() => {
    filterDonations();
  }, [searchTerm, statusFilter, projectFilter, donations]);

  const fetchDonations = async (page) => {
    const token = localStorage.getItem('bearerToken');
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/donations/all-histories?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const { data, meta } = response.data.data;
      setDonations(prev => [...prev, ...data]);
      setTotalPages(meta.lastPage);
    } catch (error) {
      toast.error('Failed to fetch donations history');
    } finally {
      setLoading(false);
    }
  };

  const filterDonations = () => {
    let filtered = donations;

    if (searchTerm) {
      filtered = filtered.filter(donation =>
        donation.user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        donation.user.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        donation.user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        donation.project.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (statusFilter) {
      filtered = filtered.filter(donation => donation.status === statusFilter);
    }

    if (projectFilter) {
      filtered = filtered.filter(donation => donation.project.name === projectFilter);
    }

    setFilteredDonations(filtered);
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const allDonations = [];
      for (let page = 1; page <= totalPages; page++) {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/donations/all-histories?page=${page}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
            'Content-Type': 'application/json',
          },
        });
        allDonations.push(...response.data.data.data);
      }
      setDonations(allDonations);
      filterDonations();
    } catch (error) {
      toast.error('Failed to fetch donations history');
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = (filter) => {
    setStatusFilter(filter);
  };

  const handleProjectFilter = (project) => {
    setProjectFilter(project);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  // Extract unique project names
  const uniqueProjectNames = [...new Set(donations.map(donation => donation.project.name))];

  return (
    <Container>
      <HeaderContainer>
        <Title>Donations History</Title>
        <SearchContainer>
          <SearchInput 
            placeholder="Search" 
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <SearchButton onClick={handleSearch}>
            <SearchIconImg src={SearchIcon} alt="Search" />
          </SearchButton>
          <FilterButton onClick={() => handleFilter('paid')}>
            <img src={FilterIcon} alt="Filter" />
            Paid
          </FilterButton>
          <FilterButton onClick={() => handleFilter('pending')}>
            <img src={FilterIcon} alt="Filter" />
            Pending
          </FilterButton>
          {/* <FilterButton onClick={() => handleProjectFilter('Project Name')}>
            <img src={FilterIcon} alt="Filter" />
            Project Name
          </FilterButton> */}
          <FilterSelect
            value={projectFilter}
            onChange={e => setProjectFilter(e.target.value)}
          >
            <option value="">All Projects</option>
            {uniqueProjectNames.map(name => (
              <option key={name} value={name}>{name}</option>
            ))}
          </FilterSelect>
        </SearchContainer>
      </HeaderContainer>

      {loading ? (
        <GlobalLoader loading={loading} />
      ) : (
        <>
          {filteredDonations.length > 0 ? (
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Donor Name</Th>
                    <Th>Email</Th>
                    <Th>Donation Type</Th>
                    <Th>Amount</Th>
                    <Th>Status</Th>
                    <Th>Project</Th>
                    <Th>Date</Th>
                  </Tr>
                </Thead>
                <tbody>
                  {filteredDonations.map(donation => (
                    <Tr key={donation.id}>
                      <Td>{donation.user.first_name} {donation.user.last_name}</Td>
                      <Td>{donation.user.email}</Td>
                      <Td>{donation.type}</Td>
                      <Td>{donation.currency} {donation.amount}</Td>
                      <Td>{donation.status}</Td>
                      <Td>{donation.project.name}</Td>
                      <Td>{(donation.created_at)}</Td>
                    </Tr>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          ) : (
            <NoDataMessage>No donations found</NoDataMessage>
          )}
        </>
      )}

      <PaginationContainer>
        <PaginationButton
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </PaginationButton>
        {[...Array(totalPages).keys()].map((page) => (
          <PaginationButton
            key={page + 1}
            onClick={() => onPageChange(page + 1)}
            disabled={currentPage === page + 1}
          >
            {page + 1}
          </PaginationButton>
        ))}
        <PaginationButton
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </PaginationButton>
      </PaginationContainer>
    </Container>
  );
};

export default DonationsHistory;
