import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import GlobalLoader from './GlobalLoader'; // Assuming you have this component set up
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProjectModal from './formModal';

const Card = styled.div`
  background-color: #333;
  border-radius: 15px;
  padding: 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  color: white;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Header = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.h2`
  font-size: 32px;
  color: #ddd;
  font-weight: 800;
  line-height: 38px;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const Date = styled.p`
  font-size: 12px;
  color: #aaa;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Image = styled.img`
  height: 310px;
  border-radius: 10px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    height: auto;
    width: 100%;
  }
`;

const Info = styled.div`
  font-size: 16px;
  color: #aaa;
  flex: 1;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const AmountSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #464646;
  padding: 10px;
  border-radius: 10px;
  margin-top: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TotalLabel = styled.div`
  font-weight: 500;
  font-size: 33.33px;
  line-height: 21.51px;
  color: #f4f4f4;
`;

const AmountDonated = styled.p`
  color: #00d986;
  font-size: 33.33px;
  font-weight: 800;
  line-height: 24.45px;
`;

const Remaining = styled.p`
  color: #a09eff;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
`;

const ProgressBarContainer = styled.div`
  width: 163px;

  @media (max-width: 768px) {
    width: 100px;
    margin-top: 10px;
  }
`;

const PeopleDonated = styled.p`
  font-size: 12px;
  color: #777;
  margin-top: 10px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const EditButton = styled.button`
  background-color: #00aa55;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: #009944;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const AnalyticsCard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('bearerToken');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/analytics`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setData(response.data.data.data); // Adjust based on actual API response
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const parseDate = (dateString) => {
    const [time, daymonth, year] = dateString.split(' ');
    const formattedTime = time.replace(/(\d{2})(\d{2})/, '$1:$2');

    const daymonthString = daymonth.toString().padStart(4, '0');
    const day = daymonthString.slice(0, 2);
    const month = daymonthString.slice(2, 4);

    const months = [
      "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ];
    const monthName = months[parseInt(month, 10) - 1];

    return `${Number(day)} ${monthName} ${year} . ${formattedTime}`;
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
    }).format(amount);
  };

  const cleanNumber = (numString) => {
    return parseFloat(numString.replace(/,/g, ''));
  };

  const getProgressColor = (percent) => {
    if (percent <= 34) return '#FF4C4C';
    if (percent <= 75) return '#FFA500';
    return '#00D986';
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleEditProject = (projectId) => {
    setSelectedProjectId(projectId);
    setIsEditMode(true);
    setIsModalVisible(true);
  };

  return (
    <>
      <GlobalLoader loading={loading} />
      {!loading && data.map((item) => (
        <Card key={item.id}>
          <Header>
            <Image src={item.cover_image} alt={item.name} />
            <Info>
              <Title>{item.name}</Title>
              <Date>{parseDate(item.created_at)}</Date>
              <AmountSection>
                <div>
                  <TotalLabel>total amount donated</TotalLabel>
                  <AmountDonated>{formatCurrency(cleanNumber(item.amount_donated))}</AmountDonated>
                  <Remaining>{formatCurrency(cleanNumber(item.goal) - cleanNumber(item.amount_donated))} remaining</Remaining>
                </div>
                <ProgressBarContainer>
                  <CircularProgressbar
                    value={cleanNumber(item.percent_complete)}
                    text={`${item.percent_complete}`}
                    styles={buildStyles({
                      textColor: getProgressColor(cleanNumber(item.percent_complete)),
                      pathColor: getProgressColor(cleanNumber(item.percent_complete)),
                      trailColor: '#393939',
                    })}
                  />
                </ProgressBarContainer>
              </AmountSection>
            </Info>
          </Header>
          <Footer>
            <PeopleDonated>{item.donators.count} people donated</PeopleDonated>
            <EditButton onClick={() => handleEditProject(item.id)}>Edit</EditButton>
          </Footer>
        </Card>
      ))}
      {isModalVisible && (
        <ProjectModal
          isVisible={isModalVisible}
          onClose={handleCloseModal}
          projectId={selectedProjectId}
          isEditMode={isEditMode}
        />
      )}
    </>
  );
};

export default AnalyticsCard;
